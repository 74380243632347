// Assuming logo.png is in the same folder as JS file
import logo from './assets/images/MalsiLogoWhite.png';
import storeButtons from './assets/images/AppStoreButtons.png';
import './assets/main.css';

function App() {
  return (
    <div className="bg-black flex flex-col h-screen items-center justify-center">
      <div className="max-w-3xl flex p-8">
        <img src={logo} alt="MALSI" />
      </div>
      <div className="relative mt-12 px-8">
        <img src={storeButtons} alt="app store buttons" />
        <div className="text-center ">
          <div className="absolute w-full h-full bg-[#000000bb] top-0 left-0 bg-gradient-to-t from-black" />
          <h2
            className="text-white absolute bottom-2 left-[calc(50%-90px)] w-[180px] 
            text-md
            sm:text-2xl
            "
            style={{ fontFamily: 'Verdana', letterSpacing: '0.04rem' }}>
            Coming Soon.
          </h2>
        </div>
      </div>
    </div>
  );
}

export default App;
